.adminPanel-usersTable{
    /* width: 70%; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 auto;
    border: #0f4c75 solid 1px;
    /* border-radius: 8px; */
}

.adminPanel-usersTableTop{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #0f4c75;
    color: #ffffff;
}

.adminPanel-usersName{
    width: 34%;
    padding-left: 6px;
}

.adminPanel-usersEmail{
    width: 48%;
}

.adminPanel-usersRole{
    width: 18%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-right: 6px;
}

.adminPanel-userItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: #d2d2d2 solid 1px;
}

.adminPanel-userItem:hover{
    background: #ebebeb;
}

/*SET ROLE*/
.setRole-dialog{
     width: 30%;
 }

.setRole-userName{
    font-size: 20px;
    font-weight: 600;
    color: #858285;
}
.setRole-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}
