.zohoCard{
    width: 200px;
    height: 200px;
    background: #ff4500;
    text-align: center;
    color: white;
    cursor: grab;
}

.zoho-Board{
    border: #cbc8c8 solid 3px;
    border-radius: 4px;
    padding: 8px 18px;
    margin: 0 15px;
    text-align: center;
}

.zoho-itemColumn{
    background: #eedac1;
    border: #4a4949 solid 1px;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 10px 0;
    text-align: center;
    cursor: grab;
}
