h1, p {
    /*font-family: Lato;*/
    font-family: 'Sree Krushnadevaraya', serif;
}

.displayItem-mainTitle{
    background: rgba(255,255,255, 0.85);
    position: sticky;
    top:0;
    z-index: 20;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #8d8b8b;
}

.displayItem-BackBtn{
    position: absolute;
    top: 5px;
    left: 15px;
}

.displayItem-actionsWrapper{
    position: absolute;
    top: 0px;
    right: 10px;
    line-height: 17px;
}

.displCand-label{
    background-color: rgb(244, 243, 243);
    font-size: 16px;
    margin-top: 10px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 7px 0 0 7px;
    border: #ff767e solid 1px;
    border-color: rgb(204, 204, 204);
}

.displCand-label.fullName{
    margin-top: 6px;
    font-size: 16px
}

.displCand-label.Cur{
    margin-bottom: 0;
    padding-top: 6px;
}

.displCand-currency{
    background-color: rgb(244, 243, 243);
    font-size: 16px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 5px;
    border-radius: 0 7px 7px 0;
    border: #ff767e solid 1px;
    border-color: rgb(204, 204, 204);
    border-left: none;
}

.displCand-input{
    min-width: 370px;
    font-size: 16px;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 0 7px 7px 0;
    border: #ff767e solid 1px;
    border-color: rgb(204, 204, 204);
    border-left: none;
}

.displCand-input.rtl{
    direction: rtl;
}

.displCand-input.name{
    margin-top: 6px;
    min-width: 15%;
    max-width: 55%;
    font-size: 16px;
}

.displCand-topNameWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.displCand-topNameItem{
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.displCand-inputCurrency{
    font-size: 16px;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius:  0;
    border: #ff767e solid 1px;
    border-color: rgb(204, 204, 204);
    border-left: none;
}

.section-title{
    margin-top: 35px;
    /* margin-left: -15px; */
    color:#0f4c75;
}

/*FOR TEXT AREA*/
.input-group{
    margin-top: 12px;
}

.input-group-stretch{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-top: 12px;
}

.input-group-text{
    background-color: rgb(244, 243, 243);
    /*font-size: 20px;*/
    font-size: 16px;
    color: #000;
}

.form-control{
    min-height: 40px !important;
    min-width: 60%;
    font-size: 12px;
}

.form-control.rtl{
    min-height: 40px !important;
    min-width: 60%;
    font-size: 12px;
    direction: rtl;
}
/*FOR SELECT*/
.picklist-box{

}

.PickHook{
    display: flex;
}

.css-2b097c-container{
    width: 50%;
}

.css-yk16xz-control{
    margin-top: 10px;
}

.css-g1d714-ValueContainer{

}

.select-label{
    background-color: rgb(244, 243, 243);
    font-size: 16px;
    margin-top: 10px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 4px;
    border-radius: 7px 0 0 7px;
    border: #ff767e solid 1px;
    border-color: rgb(204, 204, 204);
}

/*BUTTONS*/
.displCand-saveChangesBtn{
    color: #33339e;
    font-weight: 800;
    cursor: pointer;
    margin-left: 12px;
    margin-top: 10px;
}

.displCand-saveChangesBtn:hover{
    color: #0300d6;
}

.displCand-invalidValue{
    color: #de3c00;
    font-weight: 700;
    margin-left: 12px;
    margin-top: 10px;
}

.displCand-saveResult{
    margin-left:10px;
    color:grey;
    font-weight:700;
}

/*Dependency*/
.dependency-toSearchLabel{
    color: #a3a3a3;
    font-weight: 600;
    font-size: 18px;
}

.dependency-toSearchItem{
    /*background: #f5f3f1;*/
    /*padding: 3px 9px;*/
    /*border-radius: 6px;*/
    color: #4646da;
    font-weight: 600;
    margin-left:10px;
    cursor: pointer;
}

.dependency-toSearchItem:hover{
    color: #4b4bfb;
    font-weight: 700;
    margin-left:10px;
    cursor: pointer;
}
