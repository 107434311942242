.dashBoard-mainTitle{
    color: #0f4c75;
    /* margin-left: -15px; */
}

.BDTable-settings{
    width: 155px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 27px;
    background: #0f4c75;
    border-radius: 6px 6px 0 0 ;
    /*margin-left: 12px;*/
}

.BDTable-top-sticky{
    width: 100%;
    position: sticky;
    top:0;
    z-index: 20;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #0f4c75;
    color: white;
}

.candidatesTableTop{
    min-height: 32px;
    align-items: center;
}

.BDTable-top{
    width: 100%;
    position: sticky;
    top:0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #0f4c75;
    color: white;
}

.BDTable-top-title{
    width: 34%;
    padding-left: 4px;
}

.BDTable-top-column{
    width: 22%;
    border-left: #aaaaaa solid 2px;
    padding-left: 4px;
}

.BDTable-itemMain>.BDTable-top-column{
    border-left: #dcdcdc solid 2px;
}

.BDTable-itemMain.details>.BDTable-top-column{
    border-left: #dcdcdc dotted 2px;
}

.BDTable-column-double{
    width: 44%;
    padding-left: 3px;
    border-left: #dcdcdc dotted 2px;
}

.BDTable-itemMain{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.BDTable-detailsSearch{
    width: 100%;
    max-height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.BDTable-selectedSent{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #ededed;
    margin-bottom: 3px;
    padding-left: 3px;
    border-radius: 3px;
}


/*FOR CANDIDATES*/
/*table top*/
.BDTable-top-title.candidates{
    width: 25%;
}

.BDTable-top-column.tag{
    width: 12%;
}

.BDTable-top-column.actions{
    min-width: 49%;
}

.BDTable-top-column.details{
    width: 12%;
}

/*rows*/
.candidateRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    /*padding-left: 6px;*/
    /*padding-right: 8px;*/
}

.BDTable-candidateItemMain{
    width: 98%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.rowCandidateName{
    padding-left: 4px;
    width: 25%;
}

.tagParser{
    border-left: #dcdcdc solid 2px;
    width: 12%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /*justify-content: space-around;*/
    padding: 0 4px 0 4px;
    align-items: center;
}

.rowCandidateActions{
    min-width: 49%;
    border-left: #dcdcdc solid 2px;
}

.rowCandidateDetails{
    width: 12%;
    border-left: #dcdcdc solid 2px;
}

.tagItem{
    width: 100%;
    font-size: 14px;
    /*width: 21px;*/
    /*height: 21px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*border: 2px solid #bdbdbd;*/
    /*border-radius: 5px;*/
    color: #bdbdbd;
}
.tagItem-active{
    font-size: 14px;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #2329bd;
    border-radius: 50%;
    color: #2329bd;
}

.setTagBtn{
    cursor: pointer;
}
.tagItem>.setTagBtn:hover{
    color: #2329bd;
}

.tagTable-topActions>.setTagBtn{
    color: #0f4c75;
    font-size: 13px;
}

.tagTable-topActions>.setTagBtn:hover{
    color: #0000f9;
}


/*for details*/
.candidateRowDetail{
    margin: 0 auto;
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    max-height: 350px;
    overflow: auto;
}

/*CANDIDATE BAR*/
.DBbar-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tagTable-main{
    width: 48%;
    height: 40vh;
    margin-bottom: 20px;
}

.tagTable-main-long{
    width: 100%;
    height: 40vh;
    margin-bottom: 20px;
}

.tagTable-tagName{
    display:inline-block;
    color: #ffffff;
    background: #0f4c75;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px 8px 0 0;
}

.tagTable-tagBody{
    display: flex;
    flex-direction: column;
    /*background: #6f6f6f;*/
    border: #0f4c75 solid 1px;
    /* border-radius: 0 10px 10px 10px; */
    height: 90%;
    overflow: hidden;
}

.tagTable-tagList{
    height:95%;
    overflow-y: auto;
}

.tagTable-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #0f4c75;
    /* border-radius: 0 10px 0 0; */
    color: white;
    font-size: 14px;
}

.tagTable-topName{
    padding-left: 4px;
    width: 35%;
}

.tagTable-topNote{
    padding-left: 4px;
    color: #cccccc;
    border-left: #dcdcdc solid 2px;
    width: 37%
}

.tagTable-date{
    padding-left: 4px;
    color: #cccccc;
    border-left: #dcdcdc solid 2px;
    width: 13%
}

.tagTable-topActions{
    padding-right: 8px;
    padding-left: 8px;
    border-left: #dcdcdc solid 2px;
    width: 15%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tagTable-listItem{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    border-bottom: #dcdcdc solid 2px;
}

.tagTable-listItem:hover{
    background: #f4f4f4;
}

.tagTable-topName.listItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.tagTable-innerIcons{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: baseline;
}

.tagTable-header__sortable {
    cursor: pointer;
}


/*for scroll bar*/
.tagTable-topScrollImitation{
    width: 6px;
}
.tagTable-tagList::-webkit-scrollbar{
      width: 6px;
  }

.tagTable-tagList::-webkit-scrollbar-track{
    background: #d5d5d5;
}

.tagTable-tagList::-webkit-scrollbar-thumb{
    background: #8e8e8e;
    border-radius: 3px;
}

