.createCandidate-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
}

.createCandidate-inputFile{
    margin-top: 10px;
}

.uploadFile-input{
    padding-top: 2px;
    padding-bottom: 2px;
}

.createCandidate-withCv{
    display: flex;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: white;
    color: #0ab42c;
    border-radius: 50%;
    margin-top: 12px;

}

.createCandidate-withOut{
    display: flex;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: white;
    color: #aaaaaa;
    border-radius: 50%;
    margin-top: 12px;
}
