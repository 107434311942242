/*style for Avatar component*/
.avatar-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    min-width: 26px;
    /*padding: 10px;*/
    background: #cbc8c8;
    border-radius: 50%;
    color: #ffffff;
    font-weight: bold;
    margin-left: 3px;
    margin-right: 6px;
    /*padding-top: 3px;*/
}

.table-fullName-wrapper{
    display: flex;
    align-items: center;
}

.avatar-photo{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:top center;
}
