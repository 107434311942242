/*styles for component DisplayPhoto*/
.DisplayPhoto-photo{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: orange;
    margin: 10px auto;
}

.DisplayPhoto-noPhoto{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    min-height: 130px;
    border-radius: 50%;
    background: #e9e9e9;
    margin: 10px 0;
    color: white;
    font-size: 20px;
    font-weight: 600;
}
