.candidateItemParser{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    padding-right: 10px;
}

.candidateItemParser-block{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /*justify-content: center;*/
    width: 46%;
}

.candidateItemParser-blockName{
    font-weight: 600;
    color: #828682;
}

.candidateItemParser-candidateItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 8px;
    margin-right: auto;
    margin-left: auto;
    background: #ffffff;
    border: #acacac solid 3px;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
}

.candidateItemParser-candidateTitle{
    font-weight: bold;
    font-size: 16px;
    margin-left: 6px;
}

.candidateItemParser-actionBtn{
    font-size: 14px;
    font-weight: 600;
    background-color: #d7d5d2;
    color: #fff;
    border: #bcbab8 solid 2px;
    border-radius: 0 4px 4px 0;
    padding:0 16px 2px 16px;
    margin-left: 4px;
}

.candidateItemParser-actionBtn-dis{
    font-size: 14px;
    font-weight: 600;
    background-color: #cfcdca;
    color: #989898;
    border: #bcbab8 solid 2px;
    border-radius: 0 4px 4px 0;
    padding:0 6px 2px 6px;
    margin-left: 4px;
}

.candidateItemParser-actionBtn:hover{
    background-color: #e2e0dd;
}

.candidateItemParser-actionBtn-dis:hover{
    background-color: #cfcdca;
}
