.jobDependency-quickMain{
    padding-bottom: 10px;
    border-bottom: dotted 2px #d6d6d5;
}

.jobDependency-relatedSearch{
    color: #8b8b8b;
    font-weight: 500;
    font-size: 14px;
}
