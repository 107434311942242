.candidateJobParser{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.candidateJobParser-main{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
}

.candidateJobParser-jobItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 70%;
    margin-top: 8px;
    margin-right: auto;
    margin-left: auto;
    background: #ffffff;
    border: #acacac solid 3px;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
}

.candidateJobParser-jobTitle{
    color: #5e5e60;
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
}

.candidateJobParser-jobRemove{
    font-size: 14px;
    font-weight: 600;
    background-color: #d7d5d2;
    color: #fff;
    border: #bcbab8 solid 2px;
    border-radius: 0 4px 4px 0;
    padding:0 22px 2px 22px;
    margin-left: 4px;
}
