.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-Footer{
  background-color: #0f4c75;
  bottom: 40px;
  left: 0;
  width: 100%;
  height: 40px;
  margin-top: 15px;
  padding: 4px 0;
  color: #eaeaea;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.containter__main-title {
  color: #0f4c75;
  margin-left: 0;
}

.container a {
  color: #0f4c75;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*MAIN BUTTONS*/
.blueWhite-Btn{
  background: transparent;
  border: 2px solid #0f4c75 ;
  color: #0f4c75;
  border-radius: 8px;
  font-size: 14px;
  padding: 2px 8px;
  margin-right: 5px;
  cursor: pointer;
}

.blueWhite-Btn:hover{
  border: 2px solid #2220ff;
  color: #2220ff;
}

.blueWhite-Btn:disabled{
  border: 2px solid #96a3a6;
  color: #cbcacb;
}

.blueWhite-Btn:focus{
  border: 3px solid #4a4949;
  border-radius: 8px;
  color: #4a4949;
  border-radius: 8px;
}

.redWhite-Btn{
  background: transparent;
  border: 2px solid #d94e4e ;
  color: #d94e4e;
  border-radius: 8px;
  font-size: 14px;
  padding: 2px 8px;
  margin-right: 5px;
  cursor: pointer;
}

.redWhite-Btn:hover{
  border: 2px solid #ff5d5d;
  color: #ff5d5d;
}

.redWhite-Btn:focus{
  border: 3px solid #4a4949;
  border-radius: 8px;
  color: #4a4949;
}


.greyWhite-Btn{
  background: transparent;
  border: 2px solid #afafaf ;
  color: #afafaf;
  border-radius: 8px;
  font-size: 14px;
  padding: 2px 8px;
  margin-right: 5px;
  cursor: pointer;
}

.greyWhite-Btn:hover{
  border: 2px solid #c8c8c8;
  color: #c8c8c8;
}

.redWhite-Btn:focus{
  border: 3px solid #c8c8c8;
  border-radius: 8px;
  color: #c8c8c8;
}

/*filled buttons*/
.greenFill-btn{
  padding: 2px 14px;
  background-color: #0ab42c;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.greenFill-btn:hover{
  background-color: #18d32a;
}

.greenFill-btn:disabled{
    background-color: #e8e6e5;
    color: #b2b0af;
}

.blueFill-btn{
  padding: 2px 14px;
  background-color: #33339e;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.blueFill-btn:hover{
  background-color: #0300d6;
}

.blueFill-btn:disabled{
  background-color: #e8e6e5;
  color: #b2b0af;
}

.redFill-btn{
  padding: 2px 14px;
  background-color: #cf0000;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.redFill-btn:hover{
  background-color: #ee0000;
}

.redFill-btn:disabled{
  background-color: #e8e6e5;
  color: #b2b0af;
}

.greyFill-btn{
  padding: 2px 14px;
  background-color: #6c757d;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.greyFill-btn:hover{
  background:  #5a6268;
}

.greyFill-btn:disabled{
  background-color: #e8e6e5;
  color: #b2b0af;
}

.orangeFill-btn{
  padding: 2px 14px;
  background-color: #eca80b;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.orangeFill-btn:hover{
  background-color: #f4b70a;
}

.orangeFill-btn:disabled{
  background-color: #e8e6e5;
  color: #b2b0af;
}

/*tooltip*/
.tooltip-inner{
  background: #f7f7f7;
  color: #282c34;
  padding: 3px 9px;
  font-size: 12px;
  direction: rtl;
}

.bs-tooltip-top .arrow::before{
  border-width: .3rem .3rem 0;
  border-top-color: #f7f7f7;
}

.bs-tooltip-left .arrow::before{
  border-width: .3rem  0 .3rem;
  border-top-color: #f7f7f7;
}

.bs-tooltip-right .arrow::before{
  border-width: .3rem  0 .3rem;
  border-top-color: #f7f7f7;
}

/*LOADER*/
.spinner-border{
  text-align: center;
}
/*HEADER*/
.header-main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0f4c75;
}

.header-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/*HEADER NAV*/
.header-nav .nav{
  background-color: #0f4c75;
}

.header-nav a.nav-link {
  color: #fff;
}

.header-nav .nav-link.active{
  background-color: #0f4c75;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.header-nav .nav-link.active:hover{
  color: #e2e2e2;
}

.header-nav .nav-link:hover{
  color: #fff;
  text-decoration: underline;
}

/*SEARCH MAIN*/
.searchMain-wrapper{
  width: 100%;
  min-height: 50vh;
  margin: 3px 0 20px;
  border: 1px solid #bcbab8;
  /* border-radius: 8px; */
}

.searchMain-new{
  display: flex;
  justify-content: flex-end;
}

.searchMain-newBtn{
  background: transparent;
  border: 2px solid #0f4c75 ;
  color: #0f4c75;
  border-radius: 8px;
  font-size: 14px;
  padding: 2px 8px;
  margin-right: 5px;
}

.searchMain-newBtn:hover{
  border: 2px solid #2220ff;
  color: #2220ff;
}

.searchMain-newBtn:focus{
  border: 3px solid #4a4949;
  border-radius: 8px;
  color: #4a4949;
  border-radius: 8px;
}

.searchMain-title{
    display:flex;
    background-color: #0f4c75;
    color: #ffffff;
    margin-top: -1px;
}

.searchMain-item{
    display:flex;
    margin-top: 3px;
    margin-bottom: 3px;
    /*border-bottom: 1px solid #bcbab8;*/
}

.searchMain-deleteBtn{
  color: #0f4c75;
  cursor: pointer;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 700;
}

.searchMain-actionsWrapper{
  width: 15%;
  display: flex;
  justify-content: space-between;
}

/*.searchMain-item:hover{*/
/*  background-color: #eaf97a;*/
/*}*/

/*search main accordion*/
.accordion>.card{
  border-radius: 0;
  border: none;
}

.accordion>.card>.card-header{
  padding: 0;
  background: #fcfbfc;
  border-bottom: #ece4ed solid 2px;
  margin-bottom: 0;
}

.accordion>.card>.card-header:hover{
  background: #eee8ec;
}

.card-body{
  padding: 8px 5px;
  /*border-bottom: #ece4ed solid 2px;*/
  box-shadow: 0px -5px 5px -5px rgba(46, 81, 108, 0.3) inset;
 /*background: #18d32a;*/
}

/*search details*/
.searchDetailsBtn{
  color: #0f4c75;
  cursor: pointer;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 700;
}

.searchDetailsBtn-active{
  /*position: absolute;*/
  color:  #0f4c75;
  cursor: pointer;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 700;
  transform: rotate(270deg);
  display: inline-block;
}

.searchDetails-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: baseline;
}

.searchDetails-keyWordTitle{
  color: #848fa6;
  margin-right: 10px;
}

.searchDetails-keyWord{
  margin-left: 4px;
  color: #7c869c;
  font-weight: 600;
  text-transform: capitalize
}

.searchDetails-criteriaWrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.searchDetails-criteria{
  display: flex;
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  padding: 4px 10px;
  margin: 4px;
  background: #909cb5;
  color: white;
  border-radius: 20px;
}


/*SEARCH*/
.searchJob-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searchJob-box{
  text-align: center;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.searchJob-box #searchInput{
  min-width: 286px;
  font-size: 16px;
  border: #0f4c75 solid 1px;
  border-left: none;
  border-right: none;
  padding: 2px;
  margin-top: 0;
  padding-left: 8px;
}

.searchJob-box #searchInput:focus-visible {
  outline: none;
}

.searchJob-by{
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  color: #b2aeae;
}

.search-box{
  text-align: center;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.search-box #searchInput{
  min-width: 250px;
  font-size: 16px;
  border: #0f4c75 solid 1px;
  border-left: none;
  border-right: none;
  padding: 2px;
  margin-top: 0;
  padding-left: 8px;
}

.search-box #searchInput:focus-visible {
  outline: none;
}

.search-cleanBtn{
  background: #0f4c75;
  color: #ffffff;
  border: #0f4c75 solid 1px;
  border-right: none;
  padding-left: 8px;
  padding-right: 6px;
  /* border-radius: 20px 0 0 20px; */
  cursor: pointer;
}

.search-cleanBtn:hover{
  /* background: #cbc6c9; */
  color: #ffffff;
}

.search-searchBtn{
  /*background: #00a; !*blue *!*/
  background:#0f4c75;
  color: #ffffff;
  border:  #0f4c75 solid 1px;
  border-left: none;
  padding-left: 10px;
  padding-right: 12px;
  /* border-radius: 0 20px 20px 0; */
  cursor: pointer;
}

.search-searchBtn:hover{
  /* background: #cbc6c9; blue 0000f5 */
  color: #ffffff;
}

.search-box .clearLastReqest{
  cursor: pointer;
  color: #d94e4e;
  font-weight: 500;
  margin-right: 60px;
}

/*TABLE CSS*/
thead{
  position: sticky;
  top:0;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;

}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0f4c75;
  color: white;
}

.table-topLabel{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color:white;
  padding: 8px 8px 8px 18px;
}

.table-topIdx{
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 600;
}

.table-topTotalRes{
  margin-left: 4px;
  padding-left: 4px;
  border-left: #ebebeb solid 2px;
}

.table-SearchName{
  background: #c4c0c2;
  color: white;
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  margin-left: 16px;
  padding: 2px 30px;
}

.table-labelSearchSingle{
  background: #0f4c75;
  color: white;
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  margin-left: 16px;
  padding: 2px 30px;
}

.table-labelSearchBoth{
  background: #c4c0c2;
  color: white;
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  margin-left: -12px;
  padding: 2px 30px;
  z-index: -5;
}

.table-labelJob{
  background: #c4c0c2;
  color: #ffffff;
  border-right:#ffffff solid 1px;
  /*z-index: 5;*/
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  margin-left: 16px;
  padding: 2px 30px;
}

.table-BigTextWrapper{
  max-height: 65px;
  overflow: hidden;
  cursor: zoom-in;
}

.bigText-Tool>.tooltip-inner{
  max-width: 600px!important;
}

.table-showMore{
  cursor: zoom-in;
  color: #0f4c75;
  font-weight: 600;
  margin-left: 4px;
}


.table-allActionsWrapper{
  display: flex;
  justify-content: space-between;
  max-width: 150px;
  margin: 0 auto;
}

.table-createdTime{
  min-width: 50px;
}


/*MODAL WINDOW*/
.modal-link{
  color: #0f4c75;
  font-weight: bold;
  cursor: pointer;
}

.modal-link:hover{
  color: #076aff;
}

.modal-link-green{
  color: #0daa0f;
  font-weight: bold;
  cursor: pointer;
}

.modal-link-orange{
  color: #5e81ff;
  font-weight: bold;
  cursor: pointer;
}

.modal-dialog{
  max-width: 700px;
  height: 700px;
}

.modal-dialog.emailModal-dialog{
  max-width: 70%;
  max-height: 300px;
}

.modal-dialog.linkedinModal-dialog{
  max-width: 32%;
  max-height: 300px;
}

.modal-dialog.confirmModal-dialog{
  max-width: 33%;
  max-height: 200px;
}

.confirmModal-textBold{
  font-weight: 700;
  color: #6f6f6f;
  margin: 0 4px;
}

.modal-content{

}

.modal-body.cv-modal{
  height: 72vh;
}

.modal-dialog.test-dialog{
  margin: 27vh auto;
}

.advanced-saveWindow.modal-body{
  height: 100px;
  /*background: #18d32a;*/
  padding: 30px auto;
}

.createJob-saveWindow.modal-body{
  /*height: 100px;*/
  /*background: #18d32a;*/
  padding: 30px auto;
}

.advanced-saveWindow-bodyWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
}

/*for PDF viewer*/
.react-pdf__Page{
  width: 100%;
  height: 70vh;
  overflow-x: scroll;
  overflow-x: hidden;
}

.react-pdf__Page__canvas{
  width: 100% !important;
}

.buttonc{
  margin-left: 10px;
}

.pdfViewer-pagesBox{
  position: absolute;
  display: flex;
  bottom: -45px;
  left: 15px;
}

/*ADVACED SEARCH*/
.advanced-wrapper{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.advanced-label{
  min-width: 270px;
  font-size: 20px;
  font-weight: 400;
  color: #8e8273;
}

.advanced-searchName{
  font-size: 20px;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #ced4da;
  padding-left: 6px;
}

.advanced-maxCriterias{
  font-size: 12px;
  font-style: italic;
  color: rgb(180, 176, 178);
}

.advanced-notes{
  font-size: 16px;
  font-style: italic;
  color: rgb(180, 176, 178);
}

.advanced-select{
  min-width: 500px;
}

.advanced-select .css-yk16xz-control{
  margin-top: 0;
}

.advanced-criterias{
  margin-top: 15px;
}

/*ADVANCED SEARCH criterias*/
.criteria-title{
    /*text-align: center;*/
}

.criteria-titleSpan{
    padding-left: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(2, 0, 124, 0.81);
}

/*.advanced-criteriaHandler{*/
/*  font-weight: 600;*/
/*  background-color: #d7d5d2;*/
/*  color: #fff;*/
/*  border: #bcbab8 solid 2px;*/
/*  border-radius: 4px;*/
/*  padding: 4px 16px;*/
/*  !*text-shadow: 1px 1px 1px #282c34;*!*/
/*}*/

/*new criteria handler #1d1ccd */
.advanced-criteriaHandler{
  font-weight: 600;
  background-color: transparent;
  color: #1d1ccd;
  border: #1d1ccd solid 2px;
  border-radius: 6px;
  padding: 4px 16px;
  /*text-shadow: 1px 1px 1px #282c34;*/
}

.advanced-criteriaHandler:disabled{
  font-weight: 600;
  background-color: transparent;
  color: #cbcacb;
  border: #c4c3c4 solid 2px;
  border-radius: 6px;
  padding: 4px 16px;
  /*text-shadow: 1px 1px 1px #282c34;*/
}

.advanced-criterias-line{
  display: flex;
  align-items: baseline;
}

.advanced-criteria-addBtn{
  font-size: 20px;
  font-weight: 900;
  background-color: #d7d5d2;
  color: #fff;
  border: #bcbab8 solid 2px;
  border-radius: 8px;
  padding:0 10px 4px 10px;
  margin-left: 4px;
  /*text-shadow: 1px 1px 1px #282c34;*/
}

.advanced-criteria-addBtn:disabled{
  background-color: rgba(215,213,210,0.5);
  color: #fff;
  border: rgba(188,186,184,0.5) solid 2px;
}

.css-2613qy-menu{
  max-height: 200px;
}

.criteria-with{
    width: 16%;
}

.criteria-name{
  display: inline-block;
  width: 22%;
}

.criteria-name>.css-2b097c-container{
  width: 100%;
}

.criteria-condition{
    display: inline-block;
    width: 11%;
}

.criteria-condition>.css-2b097c-container{
    width: 100%;
}

.criteria-condition .css-yk16xz-control{
    border: none;
}

.criteria-condition .css-1okebmr-indicatorSeparator{
    width: 0;
}

.criteria-value{
    display: inline-block;
    width: 52%;
}

.criteria-value>.css-2b097c-container{
    width: 100%;
}

/*ADVANCED SEARCH DEPENDENCY*/
.advanced-dependencyBlock{
  display: flex;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 10px;
}

.advanced-dependencyBtnWrapper{
  min-width: 20%;
  align-self: flex-start;
}

.advanced-dependencyBox{
  display: flex;
  flex-wrap: wrap;
}

.advanced-dependencyItem{
  display: flex;
  align-items: center;
  background: #cbcac5;
  color:white;
  font-size: 15px;
  line-height: 15px;
  border-radius: 20px;
  padding: 4px 4px 4px 12px;
  margin-left: 20px;
  margin-bottom: 4px;
}

.advanced-dependencyDelete{
  color: #efefef;
  font-size: 22px;
  cursor: pointer;
}

.advanced-dependencyDelete:hover{
  color: #ffffff;
}

/*LOGIN*/
.login-box{
  margin-top: 28px;
  margin-right: 15px;
  /*margin-left: 80px;*/
}

.login-in{
  padding: 2px 14px;
  background-color: #0ab42c;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.login-in:hover{
  background-color: #18d32a;
}

.login-out{
  padding: 2px 14px;
  background-color: #33339e;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.login-out:hover{
  background-color: #0300d6;
}

.test{
  color: #18d32a;
}

.login-out:disabled{
  background-color: #e8e6e5;
  color: #b2b0af;
}

/*LINKEDIN COMPONENT*/
.linkedinModal-main{
  position: relative;
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*flex-direction: row;*/
  /*background: #f6f6f6;*/
  /*padding: 12px;*/
  /*border-radius: 4px;*/
}

.linkedinModal-cheking{
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px;
  top:0;
  left: 0;
  z-index: 100;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.75);
  text-align: center;
  color: #f8ff58;
  font-size: 22px;
  font-weight: 600;
  /*opacity: 0.5;*/
  border-radius: 4px;
}

.linkedinModal-note{
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    color: #acacac;
}

.linkedinModal-noteBold{
    margin-left: 4px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    color: #909090;
}

.linkedinModal-success{
    color:#18d32a;
}

.linkedinModal-error{
    color: #ff4500;
}

.linkedinModal-finishBtn{
    margin-left: 20px;
    background-color: #33339e;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}


/*EMAIL COMPONENT*/
.sendMail-main{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #f6f6f6;
  padding: 12px;
  border-radius: 4px;
}

.sendMail-sending{
  position: absolute;
  padding: 12px;
  top:0;
  left: 0;
  z-index: 100;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  /*opacity: 0.5;*/
  border-radius: 4px;
}

.sendMail-sendingRes{
  text-align: left;
  margin: 0 auto;
  padding: 10px;
  width: 40%;
  background: #000000;
  border-radius: 4px;
  border: white solid 1px;
  opacity: 1.0;
}

.sendMail-noteBlock{
  flex-basis:20%;
  padding-right: 8px;
  border-right: #dfdddb solid 2px;
}

.sendMail-noteBlock p{
  margin: 0;
}

.sendMail-noteMain{
  margin-top: 40px;
}

.sendMail-note{
  font-size: 18px;
  color: #545352;
  font-weight: 700;
}

.sendMail-formBlock{
  flex-basis:80%;
  flex-direction: column;
  padding-left: 10px;
}

.sendMail-label{
  background-color: rgb(244, 243, 243);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;

  padding-left: 13px;
  padding-right: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 7px 0 0 7px;
  border: #ff767e solid 1px;
  border-color: rgb(204, 204, 204);
  color: #495057;
}

.sendMail-input{
  width: 85%;
  font-size: 15px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 4px;
  padding-top: 7px;
  padding-bottom: 6px;
  border-radius: 0 7px 7px 0;
  border: #ff767e solid 1px;
  border-color: rgb(204, 204, 204);
  border-left: none;
  color: #6e6d6c;
}

.sendMail-sendToWrap{
  display: flex;
}

.sendMail-sendToWrap .css-2b097c-container{
  width: 82%;
}

.sendMail-sendToWrap .css-yk16xz-control{
  border-radius: 0 7px 7px 0;
  border-left: none;
}

.sendMail-incorrect{
  color: red;
  text-align: center;
  font-style: italic;
  font-size: 14px;
}

.sendMail-attach{
  display: flex;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 600;
  color: #737270;
}

.sendMail-btn{
  display:flex;
  justify-content:flex-end;
  margin-top: 8px;
}
