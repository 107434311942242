.zohoConnect-main{
    margin-top: 6px;
    margin-right: 15px;
    cursor: pointer;
}

.zohoConnect-mainFlex{
    display: flex;
    margin-top: 6px;
    margin-right: 15px;
    cursor: pointer;
}

.zohoConnect-connect{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #39ff2b;
    border: #39ff2b solid 2px ;
    padding: 0 6px 0 6px;
    border-radius: 5px;
}

.zohoConnect-connect:hover{
    color: #6ce6ff;
    border: #6ce6ff solid 2px ;
}

.zohoConnect-disconnect{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #e60000;
    border: #e60000 solid 2px ;
    padding: 0 6px 0 6px;
    border-radius: 5px;
}

.zohoConnect-disconnect:hover{
    color: #ff0000;
    border: #ff0000 solid 2px ;
}
