.dnd-mainTitle{
    font-size: 20px;
    font-weight: 400;
    color: #8e8273;
}
.dnd-Board{
    border: #cbc8c8 solid 3px;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 0 15px;
    height: 372px;
    overflow-y: auto;
}

.dnd-item{
    margin: 10px 0;
    cursor: grab;
}

.dnd-item-hide{
    display: none;
}

.dnd-item-disable{
    cursor: default;
    color: #6f6f6f;
}

.dnd-availableSearch{
    width: 100%;
    border-radius: 4px;
    border: #afafaf solid 1px ;
    padding-left:10px;
}
