.selectNaya-filterStatus{
    position: absolute;
    top: 8px;
    left: 10px;
    z-index: 2;
    font-size: 12px;
}

.selectNaya-filterStatus>span{
    color:#ffffff;
    margin-right: 4px;
}

.selectNaya-filterStatus>select{
    color: #6e6e6e;
    border: none;
    border-radius: 2px;
}

/*.selectNaya-filterSelect{*/
/*    border: none;*/
/*    color:#282c34;*/
/*}*/
