.user-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 20px;
    margin-right: 15px;
}

.user-name{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: #8e8e8e;
}

.user-role{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #8e8e8e;
}
