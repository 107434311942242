.prevResume-main{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: all 0.3s;
}

.prevResume-main-active{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transition: all 0.3s;
    transform: translateX(0);
}

.prevItem-blur{
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.prevItem-content{
    position: absolute;
    right: 0;
    width: 52%;
    height: 100%;
    background: #faf7f5;
    /*transition: all 0.9s;*/
    transition: all 0.5s;
    transform: translateX(0);
    padding-left: 15px;
    padding-right: 15px ;
    padding-top: 5px;
    box-shadow: -1px 0px 15px 0px #9E9A97;
    overflow-x: visible;
}

.prevItem-content-hide{
    position: absolute;
    right: 0;
    width: 52%;
    height: 100%;
    background: #faf7f5;
    /*transition: all 0.9s;*/
    transition: all 0.5s;
    transform: translateX(105%);
    padding-left: 15px;
    padding-right: 15px ;
    padding-top: 5px;
    box-shadow: -1px 0px 15px 0px #9E9A97;
    overflow-x: visible;
}


.prevItem-CV{
    /*display: none;*/
    position: absolute;
    left: 0;
    width: 45%;
    height: 100%;
    background: #faf7f5;
    transition: all 0.5s;
    transform: translateX(-105%);
    padding-left: 15px;
    padding-right: 15px ;
    padding-top: 5px;
    box-shadow: -1px 0px 15px 0px #9E9A97;
    overflow-x: hidden;
}

.prevItem-CV-active{
    /*display: inline-block;*/
    position: absolute;
    left: 0;
    width: 45%;
    height: 100%;
    background: #faf7f5;
    transition: all 0.5s;
    transform: translateX(0%);
    padding-left: 15px;
    padding-right: 15px ;
    padding-top: 5px;
    box-shadow: -1px 0px 15px 0px #9E9A97;
    overflow-x: visible;
}

.previewItem-CV-body{
    height: 88vh;
    position: relative;
    /*background: #18d32a;*/
}

.previewItem-CV-body .react-pdf__Page{
    height: 88vh;
}

.previewItem-CV-body .pdfViewer-pagesBox{
    position: absolute;
    display: flex;
    left: 15px;
    bottom: -25px;
}

.previewItem-CV-footer{
    display: flex;
    justify-content: flex-end;
}

.prevItem-CV-closeBtn{
    position: absolute;
    right: 15px;
    padding: 5px;
}

.prevItem-tab{
    height: 82vh;
    overflow: auto;
}

.prevItem-closeBtn{
    position: absolute;
    right: 25px;
    padding: 5px;
}

.prevCV-actionWrapper{
    display: flex;
    align-items: baseline;
    line-height:14px;
    margin-top: 8px;
    margin-left: 30px;
}

.prevItem-actionBox{
    width: 28px;
    display: flex;
    justify-content: center;
}

.prevItem-actionBox-job{
    width: 100%;
    display: flex;
    justify-content: center;
}

/*FOR RECURSIVE CONTAINER*/
.prevItem-title{
    margin-top: 6px;
    font-size: 19px;
    font-weight: 500;
    color:rgb(245, 114, 114);
}

/*FOR CANDIDATE LIST*/
.previewItem-candidateLink-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 75%;
}
