.role-main{
    margin-top: 6px;
    margin-right: 15px;
    cursor: pointer;
}

.role-btn{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #e3e3e3;
    border:#e3e3e3 solid 2px ;
    padding: 0 6px 0 6px;
    border-radius: 5px;
}

.role-btn:hover{
    color: #fff;
    border:#fff solid 2px ;
}

.selectRole{
    color: #33c197;
    background: inherit;
    border-radius: 4px;
    border: #33c197 solid 2px;
}

/*FOR MODAL WINDOW*/
.loginModal-dialog{
    width: 30%;
}

.loginModal-forgotSing{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 10px 0 10px;
}

.loginModal-forgotBtn{
    color: #666667;
    font-style: italic;
    font-weight: 400;
    cursor: pointer;
}

.loginModal-forgotBtn:hover{
    color: #060606;
}

.loginModal-pass{
    position: relative;
    margin-top: 15px;
}

.loginModal-passEye{
    position: absolute;
    top: 3px;
    right: 6px;
}

/*SING IN*/
.singIn-singBtn{
     font-weight: 500;
     color: #0000a8;
     cursor: pointer;
 }

.singIn-singBtn:hover{
    color: #0000f7;
}

.singIn-fullName{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.singIn-body{
    position: relative;
    width: 100%;
    padding: 25px 70px;
}

.singIn-cover{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    top:0;
    left:0;
}

.singIn-sending{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 70%;
    margin: 30px auto;
}

.singIn-success{
    font-size: 22px;
    font-weight: 600;
    color: #1deb1d;
}

.singIn-error{
    font-size: 22px;
    font-weight: 600;
    color: #ff0000;
}
