.resetPassword-main{
    width: 60%;
    margin: 40px auto;
}

.resetPassword-btnBlock{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: 15px;
}

.resetPassword-success{
    margin-top: 10px;
    color: #0ab42c;
}

.resetPassword-error{
    margin-top: 10px;
    color: #e60000;
}

