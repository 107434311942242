.contactsList-modalDialog{
    width: 35%;
}

.contactItem-main{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: solid 1px #dee2e6;
    padding: 4px 2px;
    margin: 3px 0;
}

.contactItem-main:hover{
    background: #f4f4f4;
}

.contactItem-actionBtn{
    cursor: pointer;
    color: #898e89;
}

.contactItem-actionBtn:hover{
    cursor: pointer;
    color: #4c4f4c;
}

.contactItem-actionBtn-active{
    cursor: pointer;
    color: #1fca2e;
}

.contactItem-editContact{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.contactItem-contactInput{
    margin: 0 3px;
}

.contactItem-email{
    padding-left: 8px;
    color: #464946;
}
