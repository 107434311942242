.notes-displayMain{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.notes-addNote{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 2px;
    font-size: 18px;
    color: #0f4c75;
    border: #0f4c75 solid 2px;
    border-radius: 6px;
    cursor: pointer;
}

.notes-addNote:hover{
    color: #ffffff;
    border: #0000cd solid 2px;
    background: #0000cd;
}

.notes-addModalMain{
    max-width: 40%;
}

.notes-showNotesWrapper{
    display: flex;
    justify-content: center;
    border-top: solid 2px #7b7b7b;
}

.notes-showNotesBtn{
    cursor: pointer;
    color: #00005e;
}

.notes-showNotesBtn:hover{
    color: #0000b9;
}

/*NOTES FORM*/
.notesForm-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background: #f6f6f6;
    padding: 8px;
    border-radius: 4px;
}

.notesForm-form{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.notesForm-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.notes.css-2b097c-container{
    width: 120px;
}

.notesForm-title{
    width:250px;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 4px;
    padding-top: 7px;
    padding-bottom: 6px;
    border-radius: 0 7px 7px 0;
    border: #ff767e solid 1px;
    border-color: rgb(204, 204, 204);
    border-left: none;
    color: #6e6d6c;
}

.notes-noteBox{
    max-height: 40vh;
    overflow: auto;
}

.notes-itemMain{
    margin-top: 8px;
    background: #ffffff;
    border: #acacac solid 3px;
    border-radius: 6px;
    border-spacing:0;
    border-collapse: collapse;
}

.notes-itemTop{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #acacac;
    color: #ffffff;
    font-size: 15px;
    padding: 3px;
}

.notes-noteIcon{
    color: #ffffff;
    border: #ffffff solid 2px;
    border-radius: 3px;
    padding-left: 3px;
    padding-right: 3px;
}

.notes-userParser{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 20px;
    align-items: center;
}

.notes-dateParser{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.notes-dateItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.notes-content{
    font-size: 14px;
    padding:10px 6px;
}

.notes-itemBottom{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-bottom: 3px;
}

/*for disabled select*/
 .isDisable>.css-1fhf3k1-control{
    width: 130px;
      margin-top: 10px;
  }

 .notesForm-fieldsTitle{
     font-size: 16px;
     font-weight: 600;
     color: #4a4949;
     margin-right: 10px;
 }

 /*FOR TAG*/
.notes-addNoteTag{
    color: #0f4c75;
    cursor: pointer;
}

.notes-addNoteTag:hover{
    color: #3e3eff;
}

.tag-Select{
    border: rgb(204, 204, 204) solid 1px;
    border-radius: 0 6px 6px 0;
    border-left: none;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 10px;
    margin-bottom: 8px;
}
