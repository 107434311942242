.addToJob-modalDialog{
    max-width: 40%;
}
.addToJob-modalDialog .modal-body{
    position: relative;
    background: #ffffff;
    max-width: 100%;
    min-height: 10vh;
    max-height: 70vh;
    overflow-y: auto;
}

.addToJob-modalError{
    position: absolute;
    background: rgba(0,0,0,0.5);
    /*color: red;*/
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.addToJob-listAvailableMain{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addToJob-listAvailableTitle{
    font-size: 18px;
    font-weight: 500;
    color: #acacac;
}

.addToJob-listItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    /*border-bottom: #4a4949 solid 1px;*/
    border-radius: 4px;
    background: #d5d5d5;
    color: white;
    margin: 3px 0;
    padding: 8px;
}

.addToJob-listItem:hover{
    background: #cfcfcf;
}

.addToJob-listItemHide{
    display: none;
}

.addToJob-handlerBtn{
    color:#0000a8;
    margin-right: 6px;
    cursor: pointer;
}

.addToJob-handlerBtn:hover{
    color: #0000ff;
}

